import { Link } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const Page = ({ title, uri, featuredImage }) => {
  return (
    <div className="w-full lg:w-2/4 px-10">
      <Link
        to={uri}
        itemProp="url"
        className="block text-black font-thin text-xl text-left py-5"
      >
        {featuredImage?.data && (
          <GatsbyImage
            className="block w-full"
            image={featuredImage?.data}
            alt={featuredImage.alt}
            style={{ marginBottom: 50 }}
          />
        )}
        {parse(title)}
      </Link>

      <Link className="block text-black font-thin text-xl text-left" to={uri}>
        → Read Case Study
      </Link>
    </div>
  )
}

export default Page
