import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Page from "../components/caseStudySection"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const CaseStudyTemplate = ({ data: { caseStudy, allCaseStudies } }) => {
  const tagList = caseStudy.tags.nodes.map(tag => tag)

  const caseStudies = allCaseStudies.nodes

  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo title={caseStudy.title} description={caseStudy.excerpt.replace(/(<([^>]+)>)/gi, "")} />
      <div className="container px-5 max-w-5xl mx-auto">
        <article>
          <h1
            className="text-3xl md:text-4xl lg:text-5xl max-w-3xl p-12 text-center mx-auto text-blue font-bold"
            itemProp="headline"
          >
            {parse(caseStudy.title)}
          </h1>

          <nav className="max-w-2xl mx-auto">
            <ul className="flex justify-center flex-wrap list-none p-0">
              {tagList.map(item => (
                <li
                  className="m-2 text-white font-light leading-normal bg-shadeBlue"
                  key={item.id}
                >
                  {item.posts.nodes.length ?
                    <Link className="p-2" to={`/blog/tag/${item.slug}`} >
                      {parse(item.name)}
                    </Link> :
                    <span className="p-2">
                      {parse(item.name)}
                    </span>
                  }
                </li>
              ))}
            </ul>
          </nav>

          {!!caseStudy.content && (
            <section
              className="py-32 leading-8 text-darkGray text-lg font-light blog-post"
              itemProp="articleBody"
            >
              {parse(caseStudy.content)}
            </section>
          )}
        </article>
      </div>
      <div className="bg-lightBlue py-10 lg:p-10">
        <div className="container text-center mx-auto py-10 lg:p-10 relative">
          <div className="flex flex-wrap w-full justify-between text-blue items-center">
            <h2 className="text-4xl text-left p-10 font-bold ">Case Studies</h2>
            {/* <Link
              to="/page"
              className="border-b-2 mx-10 large:m-0 font-medium border-blue md:block "
            >
              Read All ↗
            </Link> */}
          </div>
          {caseStudies &&
            <div className="lg:flex">
              {caseStudies.slice(0, 2).map(caseStudyPage => (
                <Page
                  key={caseStudyPage.id}
                  title={caseStudyPage.title}
                  uri={caseStudyPage.uri}
                  featuredImage={{
                    data: caseStudyPage.featuredImage?.node?.localFile?.childImageSharp
                      ?.gatsbyImageData,
                    alt: caseStudyPage.featuredImage?.node?.altText || ``,
                  }}
                />
              ))}
            </div>}
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query caseStudyById($id: String!) {
    caseStudy: wpPage(id: { eq: $id }) {
      id
      uri
      title
      content
      excerpt
      author {
        node {
          avatar {
            url
          }
          id
          name
          uri
          description
        }
      }
      categories {
        nodes {
          id
          name
          link
        }
      }
      tags {
        nodes {
          id
          link
          name
          slug
          posts {
            nodes {
              id
              title
            }
          }
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    allCaseStudies: allWpPage(
      filter: { language: { slug: { eq: "en" } }, id: { ne: $id }, categories: {nodes: {elemMatch: {slug: {eq: "case-study"}}}}}
    ) {
      nodes {
        id
        uri
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
